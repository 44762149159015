import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Redirect, useLocation, useHistory } from "react-router";
import { checkLoginStatus } from "../store/api";
import { setUser } from "../store/modules/navbar";

function AuthWrapper({ children }) {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const [loggedIn, setLoggedIn] = useState(false);

  const checkIfUserLoggedIn = async () => {
    try {
      const { data } = await checkLoginStatus();
      dispatch(setUser(data.user));
      setLoggedIn(true);
    } catch (err) {
      return history.push("/login");
    }
  };
  React.useEffect(() => {
    checkIfUserLoggedIn();
  }, [location]);
  return <>{loggedIn ? children : null}</>;
}

export default AuthWrapper;
