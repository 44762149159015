import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentReport, reportGenerated } from "../store/modules/report";
import { Col, Divider, Row, Space, Table, Button } from "antd";
import { SortOrder } from "antd/lib/table/interface";
import { DRAWER_TYPES, activateDrawer } from "../store/modules/drawer";
import { getLoadingStatus } from "../store/modules/loading";

function ViewReport() {
  const dispatch = useDispatch();
  const currentReport = useSelector(getCurrentReport);
  const refreshing = useSelector(getLoadingStatus);

  const openClientDrawer = (client) => {
    dispatch(
      activateDrawer({
        type: DRAWER_TYPES.CLIENT,
        data: client,
      })
    );
  };

  const columns = [
    {
      title: "Client",
      dataIndex: "name",
      key: "name",
      sortOrder: "ascend" as SortOrder,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "Total Hours",
      dataIndex: "totalHours",
      key: "totalHours",
    },
    {
      title: "Billable Hours",
      dataIndex: "billableHours",
      key: "billableHours",
    },
    {
      title: "Billable Amount",
      dataIndex: "billableAmount",
      key: "billableAmount",
    },
    {
      title: "Invoice Total",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => openClientDrawer(record)}
            type="primary"
            style={{ background: "green" }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const filterByApproval = (data, approvalStatus) => {
    return data.filter((client) => client.approved === approvalStatus);
  };

  return (
    <div className="mt-10 px-10">
      <h1 className="text-4xl font-medium text-center">Review Reports</h1>
      <Row justify="center" className="mt-10" gutter={40}>
        <Col span={12}>
          <h3>Pending</h3>
          <Table
            loading={refreshing}
            bordered
            pagination={{
              defaultPageSize: 8,
            }}
            size="middle"
            columns={columns}
            dataSource={filterByApproval(currentReport?.clients, false)}
          />
        </Col>

        <Col span={12}>
          <h3>Reviewed</h3>
          <Table
            bordered
            loading={refreshing}
            pagination={{
              defaultPageSize: 8,
            }}
            size="middle"
            columns={columns}
            dataSource={filterByApproval(currentReport?.clients, true)}
          />
        </Col>
      </Row>
    </div>
  );
}

export default ViewReport;
