import { Form, Input, InputNumber, Modal, Select } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createCustomEntry, getBillItems } from "../store/api";
import { getCurrentReport, reportGenerated } from "../store/modules/report";

function CustomEntryModal({ open, onCancel, client }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [billItems, setBillItems] = useState<Record<any, any>[]>([]);
  const report = useSelector(getCurrentReport);

  useEffect(() => {
    getBillItems().then(({ data }) => {
      setBillItems(data.items);
    });
  }, []);

  const submitEntry = () => {
    setIsLoading(true);
    form
      .validateFields()
      .then(async (values) => {
        const jsonValue = JSON.parse(values["billItem"]);
        values = {
          ...values,
          billAccountName: jsonValue.value,
          billAccountId: jsonValue._id,
        };
        const response = await createCustomEntry(
          values,
          client._id,
          report._id
        );
        dispatch(reportGenerated(response.data.report));
        onCancel(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setIsLoading(false));
  };

  return (
    <Modal
      title="Add Custom Entry"
      visible={open}
      onCancel={() => onCancel(false)}
      onOk={submitEntry}
      destroyOnClose
      confirmLoading={isLoading}
    >
      <Form
        preserve={false}
        form={form}
        layout="vertical"
        initialValues={{ amount: 0 }}
      >
        <Form.Item name="name" label="Entry Name" rules={[{ required: true }]}>
          <Input />
        </Form.Item>
        <Form.Item name="type" label="Entry Type" rules={[{ required: true }]}>
          <Select placeholder="Select entry type">
            <Select.Option value="DISCOUNT">Discount</Select.Option>
            <Select.Option value="CHARGE">Charge</Select.Option>
            <Select.Option value="DEPOSIT">Deposit</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          name="billItem"
          label="Bill.com Item"
          rules={[{ required: true }]}
        >
          <Select placeholder="Select Bill type">
            {billItems.map((item) => (
              <Select.Option key={item._id} value={`${JSON.stringify(item)}`}>
                {item.value}
              </Select.Option>
            ))}{" "}
          </Select>
        </Form.Item>
        <Form.Item name="amount" label="Amount" rules={[{ required: true }]}>
          <InputNumber
            formatter={(value) =>
              `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            }
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default CustomEntryModal;
