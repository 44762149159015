import { Button, Space, Table } from "antd";
import confirm from "antd/lib/modal/confirm";
import React, { useState } from "react";
import { AiFillDelete, AiOutlineExclamationCircle } from "react-icons/ai";
import { useDispatch, useSelector } from "react-redux";
import { deleteCustomEntry } from "../store/api";
import { DRAWER_TYPES, activateNestedDrawer } from "../store/modules/drawer";
import { getCurrentReport, reportGenerated } from "../store/modules/report";

function ChargesTable({ data }) {
  const dispatch = useDispatch();
  const report = useSelector(getCurrentReport);
  const [deleteAlertModal, setDeleteAlertModal] = useState(false);

  const deleteEntry = async (entry, reportId) => {
    confirm({
      title: "Are you sure you want to delete this entry?",
      icon: <AiOutlineExclamationCircle />,
      okType: "danger",
      okText: "Yes",
      onOk: () => {
        return new Promise((resolve, reject) => {
          deleteCustomEntry(entry, reportId)
            .then((response) => {
              dispatch(reportGenerated(response.data.report));
              return resolve(true);
            })
            .catch(() => reject);
        }).catch((err) => console.log(err));
      },
      onCancel() {},
    });
  };

  const columns = [
    {
      title: "NAME",
      dataIndex: ["meta", "name"],
    },
    {
      title: "TYPE",
      dataIndex: "entryType",
    },
    {
      title: "ATTACHED TO",
      dataIndex: "baseType",
    },
    {
      title: "Bill.com Item",
      dataIndex: ["meta", "billAccountName"],
    },
    {
      title: "AMOUNT",
      dataIndex: "amount",
    },
    {
      title: "ACTION",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => deleteEntry(record, report._id)}
            style={{ background: "red" }}
            shape="circle"
            size="small"
            icon={
              <AiFillDelete
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  color: "white",
                }}
              />
            }
          ></Button>
        </Space>
      ),
    },
  ];

  return (
    <Table
      pagination={{
        defaultPageSize: 6,
      }}
      size="large"
      columns={columns}
      dataSource={data}
      id="project-table"
      rowClassName={(record) =>
        `editable-row ${record.meta.billAccountId ? "" : "invalid"}`
      }
    />
  );
}

export default ChargesTable;
