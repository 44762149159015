import { createSlice } from "@reduxjs/toolkit";

export const reportSlice = createSlice({
  name: "report",
  initialState: {
    currentReport: {
      _id: null,
      clients: [{ _id: 0, approved: false, includeItemization: false }],
    },
  },
  reducers: {
    reportGenerated(state, action) {
      state.currentReport = action.payload;
    },
    updateReduxClientApproval(state, action) {
      state.currentReport.clients.forEach((client, index, clients) => {
        if (client._id === action.payload.id) {
          clients[index].approved = action.payload.approveStatus;
          clients[index].includeItemization = action.payload.includeItemization;
        }
      });
    },
  },
});

export const getCurrentReport = (state) => state.report.currentReport;
export const getClientById = (state, action) =>
  state.report.currentReport.clients.filter(
    (client) => client._id === action.payload
  )[0];

export const { reportGenerated, updateReduxClientApproval } =
  reportSlice.actions;
export default reportSlice.reducer;
