import { Button, Col, Drawer, Row, Space, Table } from "antd";
import React from "react";
import { AiOutlineDollar, AiOutlineFieldTime } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { removeNestedSidebar } from "../store/modules/drawer";
import DetailsTable from "./DetailsTable";
import MetricCard from "./MetricCard";

function NestedDrawerWrapper({ visible, children, title }) {
  const dispatch = useDispatch();
  return (
    <Drawer
      onClose={() => dispatch(removeNestedSidebar())}
      title={title}
      width="60%"
      visible={visible}
      placement="right"
      push={{ distance: 700 }}
      extra={
        <Space>
          <Button onClick={() => dispatch(removeNestedSidebar())}>
            Cancel
          </Button>
        </Space>
      }
      destroyOnClose
    >
      {children}
    </Drawer>
  );
}

export default NestedDrawerWrapper;
