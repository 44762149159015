import { createSlice } from "@reduxjs/toolkit";

export const stepSlice = createSlice({
  name: "steps",
  initialState: {
    currentStep: 0,
    user: {
      firstName: "",
      lastName: "",
    },
  },
  reducers: {
    incrementStep: (state) => {
      state.currentStep += 1;
    },
    decrementStep: (state) => {
      state.currentStep -= 1;
    },
    setStep: (state, action) => {
      state.currentStep = action.payload;
    },
    setUser: (state, action) => {
      state.user.firstName = action.payload.first_name;
      state.user.lastName = action.payload.last_name;
    },
  },
});

export const getCurrentStep = (state) => state.steps.currentStep;
export const getCurrentUser = (state) =>
  state.steps.user.firstName + " " + state.steps.user.lastName;
export const { incrementStep, decrementStep, setUser, setStep } =
  stepSlice.actions;
export default stepSlice.reducer;
