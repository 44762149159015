import { configureStore } from "@reduxjs/toolkit";
import asyncFunctionMiddleware from "./asyncStore";

import stepReducer from "./modules/navbar";
import reportReducer from "./modules/report";
import loadingReducer from "./modules/loading";
import drawerReducer from "./modules/drawer";

const store = configureStore({
  reducer: {
    steps: stepReducer,
    report: reportReducer,
    loading: loadingReducer,
    drawer: drawerReducer,
  },
  middleware: [asyncFunctionMiddleware],
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
