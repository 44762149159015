import { Button, Col, Divider, notification, Row, Table } from "antd";
import confirm from "antd/lib/modal/confirm";
import { SortOrder } from "antd/lib/table/interface";
import moment from "moment";
import React from "react";
import { useSelector } from "react-redux";
import { getReportFiles, getReportSummary, submitInvoices } from "../store/api";
import { getCurrentReport } from "../store/modules/report";

function ApproveClientsTable({ data }) {
  const columns = [
    {
      title: "CLIENT",
      dataIndex: "name",
      key: "name",
      sortOrder: "ascend" as SortOrder,
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: "TOTAL HOURS",
      dataIndex: "totalHours",
      key: "totalHours",
    },
    {
      title: "BILLABLE HOURS",
      dataIndex: "billableHours",
      key: "billableHours",
    },
    {
      title: "AMOUNT",
      dataIndex: "invoiceTotal",
      key: "invoiceTotal",
    },
    {
      title: "INCLUDE ITEMIZATION",
      dataIndex: "includeItemization",
      key: "includeItemization",
      render: (key) => {
        return key ? "True" : "False";
      },
    },
  ];

  return (
    <Table
      pagination={{
        defaultPageSize: 10,
      }}
      size="large"
      columns={columns}
      dataSource={data}
      id="project-table"
    />
  );
}

function SubmitReport() {
  const report = useSelector(getCurrentReport);
  const [data, setData] = React.useState<{ [key: string]: any }>({});
  const [loading, setLoading] = React.useState(false);

  React.useEffect(() => {
    getReportSummary(report._id).then((response) => setData(response.data));
  }, []);

  const handleSubmitInvoices = async () => {
    setLoading(true);
    submitInvoices(report._id)
      .then(async (e) => {
        if (e.data.requiresAudit) {
          const res = await getReportFiles(report._id);
          const url = window.URL.createObjectURL(new Blob([res.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute(
            "download",
            `${moment().format("YYYY-MM")}_report.zip`
          );
          document.body.appendChild(link);
          link.click();
        }
        confirm({
          content: e.data.message,
          type: "success",
          onOk: () => {
            window.location.href = `${window.location.origin}`;
          },
          okText: "Go Home",
        });
      })
      .catch((e) =>
        notification.error({
          message: "Error!",
          description: e.response.data.error.message,
          placement: "bottomLeft",
          duration: 4,
        })
      )
      .finally(() => setLoading(false));
  };

  return (
    <div className="mt-10 px-12">
      <h1 className="text-4xl font-medium text-center">Submit Invoices</h1>
      <Row className="mt-10" gutter={32}>
        <Col span={16}>
          <ApproveClientsTable data={data.approvedClients} />
        </Col>
        <Col className="pt-4" span={8}>
          <Row justify="space-between" gutter={56}>
            <Col>
              <h1>Total Hours:</h1>
            </Col>
            <Col>
              <h1>{data.summary?.totalHours}</h1>{" "}
            </Col>
          </Row>
          <Row justify="space-between" gutter={56}>
            <Col>
              <h1>Billable Hours:</h1>
            </Col>
            <Col>
              <h1>{data.summary?.totalBillableHours}</h1>{" "}
            </Col>
            <Divider orientation="center" />
          </Row>
          <Row justify="space-between" gutter={56}>
            <Col>
              <h1>Total Discounts Given:</h1>
            </Col>
            <Col>
              <h1>${data.summary?.totalDiscounts}</h1>{" "}
            </Col>
          </Row>
          <Row justify="space-between" gutter={56}>
            <Col>
              <h1>Total Deposits Applied:</h1>
            </Col>
            <Col>
              <h1>${data.summary?.totalDepositApplied}</h1>{" "}
            </Col>
            <Divider orientation="center" />
          </Row>
          <Row justify="space-between" gutter={56}>
            <Col>
              <h1>Total Amount:</h1>
            </Col>
            <Col>
              <h1>${data.summary?.invoiceTotal}</h1>{" "}
            </Col>
          </Row>
          <Row className="mt-4" justify="center">
            <Button
              loading={loading}
              onClick={handleSubmitInvoices}
              size="large"
              type="primary"
            >
              Submit Invoices
            </Button>
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default SubmitReport;
