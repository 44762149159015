import { Middleware } from "@reduxjs/toolkit";
import { RootState } from ".";
import { ReduxAsyncMiddlewareObj } from "./api";

function determineActionIsAsync(
  action: ReduxAsyncMiddlewareObj | {}
): action is ReduxAsyncMiddlewareObj {
  if ((action as ReduxAsyncMiddlewareObj).call) {
    return true;
  }

  return false;
}

const asyncFunctionMiddleware: Middleware = (storeAPI) => (next) => async (
  action
) => {
  if (determineActionIsAsync(action)) {
    console.log("here");
    return await action.call(
      storeAPI.dispatch,
      storeAPI.getState,
      action.payload
    );
  }

  // Otherwise, it's a normal action - send it onwards
  return next(action);
};

export default asyncFunctionMiddleware;
