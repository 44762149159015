import { Button, Col, Divider, Drawer, Row, Space, Table } from "antd";
import React from "react";
import { AiOutlineDollar, AiOutlineFieldTime } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { removeNestedSidebar } from "../store/modules/drawer";
import DetailsTable from "./DetailsTable";
import MetricCard from "./MetricCard";

function DetailNestedDrawer({ data }) {
  console.log(data);
  return (
    <>
      <Row gutter={48}>
        <Col span={24} xl={6}>
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "bolder",
            }}
          >
            Employee
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {data?.user.name}
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            {new Date(data?.created_at).toDateString()}
          </h1>
        </Col>
        <Col span={12} xl={6}>
          <div className="mb-4">
            <MetricCard
              title="Hours"
              value={data?.hours}
              icon={<AiOutlineFieldTime />}
            />
          </div>
          <div className="">
            <MetricCard
              title="Cost Rate"
              value={"$" + Number(data?.cost_rate).toFixed(2)}
              icon={<AiOutlineDollar />}
            />
          </div>
        </Col>
        <Col span={12} xl={6}>
          <div className="mb-4">
            <MetricCard
              title="Billable Amount"
              value={
                "$" +
                (Number(data?.billable_rate) * Number(data?.hours)).toFixed(2)
              }
              icon={<AiOutlineDollar />}
            />
          </div>
          <div className="mb-4">
            <MetricCard
              title="Billable Rate"
              value={"$" + Number(data?.billable_rate).toFixed(2)}
              icon={<AiOutlineDollar />}
            />
          </div>
        </Col>
        <Col span={12} xl={6}>
          <div className="mb-4">
            <MetricCard
              title="Cost Amount"
              value={
                "$" + (Number(data?.cost_rate) * Number(data?.hours)).toFixed(2)
              }
              icon={<AiOutlineDollar />}
            />
          </div>
        </Col>
      </Row>
      <Divider />
      <Row>
        <Col span={12} className="border-r">
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "bolder",
            }}
          >
            Project
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {data?.project.name}
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            Code: {data?.project.code}
          </h1>
        </Col>
        <Col span={12} className="pl-3">
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "bolder",
            }}
          >
            Details
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            Task: {data?.task.name}
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            Notes: {data?.notes}
          </h1>
        </Col>
      </Row>
    </>
  );
}

export default DetailNestedDrawer;
