import { Statistic } from "antd";
import React from "react";
import { IconContext } from "react-icons";
import "./index.css";

function MetricCard({ title, value, icon }) {
  return (
    <IconContext.Provider
      value={{
        color: "green",
        size: "47px",
        style: {
          background: "#F4FCF6",
          borderRadius: "50%",
          padding: "6px",
        },
      }}
    >
      <div className="flex items-center w-20 whitespace-nowrap">
        <div className="mr-2">{icon}</div>
        <Statistic
          title={title}
          value={value}
          style={{
            fontSize: "16px",
          }}
          valueStyle={{
            fontSize: "24px",
            fontWeight: "bold",
          }}
        />
      </div>
    </IconContext.Provider>
  );
}

export default MetricCard;
