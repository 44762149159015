import {
  Button,
  Space,
  Table,
  Badge,
  Form,
  FormInstance,
  Input,
  Select,
} from "antd";
import React, { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { deferProjects, getBillItems, updateProject } from "../store/api";
import {
  DRAWER_TYPES,
  activateNestedDrawer,
  getDrawerState,
} from "../store/modules/drawer";
import { getCurrentReport, reportGenerated } from "../store/modules/report";
const EditableContext = React.createContext<FormInstance<any> | null>(null);

const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};

const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const [billItems, setBillItems] = useState<Record<any, any>[]>([]);
  const inputRef = useRef<Input>(null);
  const form = useContext(EditableContext)!;

  useEffect(() => {
    if (editing) {
      if (billItems.length == 0) {
        getBillItems().then(({ data }) => {
          setBillItems(data.items);
        });
      }
      inputRef.current!.focus();
    }
  }, [editing]);

  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({ [dataIndex]: record[dataIndex] });
  };

  const save = async () => {
    try {
      const values = await form.validateFields();
      const jsonValue = JSON.parse(values[dataIndex]);
      toggleEdit();
      handleSave({
        ...record,
        billAccountName: jsonValue.value,
        billAccountId: jsonValue._id,
      });
    } catch (errInfo) {
      console.log("Save failed:", errInfo);
    }
  };

  let childNode = children;

  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{ margin: 0 }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `Please select an item.`,
          },
        ]}
      >
        <Select
          showSearch
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          size="large"
          ref={inputRef}
          onBlur={save}
          style={{
            minWidth: "150px",
          }}
        >
          {billItems.map((item) => (
            <Select.Option key={item._id} value={`${JSON.stringify(item)}`}>
              {item.value}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{ paddingRight: 24 }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }

  return <td {...restProps}>{childNode}</td>;
};

function ProjectTable({ data, clientId }) {
  const [selectedProjects, setSelectedProjects] = useState([]);
  const [selectedProjectKeys, setSelectedProjectKeys] = useState([]);
  const [tableLoading, setTableLoading] = useState(false);

  const drawerState = useSelector(getDrawerState);
  const report = useSelector(getCurrentReport);
  const dispatch = useDispatch();

  const openProjectDrawer = (project) => {
    const projectEntries = drawerState.data.rawEntries.filter(
      (entry) => entry.project.id === project._id
    );

    dispatch(
      activateNestedDrawer({
        type: DRAWER_TYPES.PROJECT,
        data: {
          projectEntries,
          ...project,
        },
      })
    );
  };

  const handleSave = async (row) => {
    setTableLoading(true);
    const index = data.findIndex((item) => row._id === item._id);
    const item = data[index];
    try {
      const response = await updateProject({
        clientId,
        reportId: report._id,
        data: { ...row, _id: item._id },
      });
      dispatch(reportGenerated(response.data.report));
    } catch (err) {
      console.log(err);
    } finally {
      setTableLoading(false);
    }
  };

  const columns = [
    {
      title: "DEFERRED",
      dataIndex: "omitted",
      render: (text, record) => {
        return (
          <Badge
            status={text ? "error" : "success"}
            text={text ? "Yes" : "No"}
          />
        );
      },
    },
    {
      title: "PROJECT",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "CODE",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Bill.com Item",
      key: "billAccountName",
      dataIndex: "billAccountName",
      editable: true,
    },
    {
      title: "BILLABLE HOURS",
      dataIndex: "billableHours",
      key: "billableHours",
    },
    {
      title: "BILLABLE AMOUNT",
      dataIndex: "billableAmount",
      key: "billableAmount",
    },
    {
      title: "INVOICE AMOUNT",
      dataIndex: "invoiceAmount",
      key: "invoiceAmount",
    },
    {
      title: "ACTION",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => openProjectDrawer(record)}
            type="primary"
            style={{ background: "green" }}
          >
            View
          </Button>
        </Space>
      ),
    },
  ];

  const deferSelectedProjects = (deferValue) => {
    if (selectedProjectKeys.length === 0) return;
    setTableLoading(true);
    deferProjects(
      selectedProjectKeys,
      deferValue,
      drawerState.data._id,
      report._id
    )
      .then((response) => {
        dispatch(reportGenerated(response.data.report));
        setSelectedProjectKeys([]);
        setSelectedProjects([]);
      })
      .catch((err) => console.log(err.message))
      .finally(() => setTableLoading(false));
  };

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedProjectKeys(selectedRowKeys);
      setSelectedProjects(selectedRows);
    },
    getCheckboxProps: (record) => {
      return {
        name: record.name,
        id: record._id,
      };
    },
    selectedRowKeys: selectedProjectKeys,
  };

  const selectionsConfig = [
    {
      key: "toggle_deferrment",
      text: "Defer Project/s",
      onSelect: (keys) => deferSelectedProjects(true),
    },
    {
      key: "toggle_undeferrment",
      text: "Include Project/s",
      onSelect: (keys) => deferSelectedProjects(false),
    },
  ];

  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };

  const tableColumns = columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave: handleSave,
      }),
    };
  });

  return (
    <>
      <Table
        pagination={{
          defaultPageSize: 4,
        }}
        loading={tableLoading}
        size="large"
        columns={tableColumns}
        dataSource={data?.map((record) => ({
          ...record,
          key: record._id,
        }))}
        rowClassName={(record) =>
          `editable-row ${record.billAccountId ? "" : "invalid"}`
        }
        id="project-table"
        rowSelection={{
          type: "checkbox",
          ...rowSelection,
          selections: selectionsConfig,
        }}
        components={components}
      />
    </>
  );
}

export default ProjectTable;
