import { Button } from "antd";
import React from "react";
import { login } from "../store/api";

function Login() {
  return (
    <div className="min-h-screen bg-gradient-to-br from-green-100 to-green-900 pt-12">
      <div className="flex items-center justify-center">
        <Button onClick={login} size="large" type="primary">
          Login with Harvest
        </Button>
      </div>
    </div>
  );
}

export default Login;
