import { createSlice } from "@reduxjs/toolkit";

export const loadingSlice = createSlice({
  name: "loading",
  initialState: {
    active: false,
  },
  reducers: {
    setLoading(state, action) {
      state.active = action.payload;
    },
  },
});

export const getLoadingStatus = (state) => state.loading.active;

export const { setLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
