import { Button, DatePicker, Modal, Input, Form } from "antd";
import React from "react";
import { useDispatch } from "react-redux";
import { generateReport, getAllReports } from "../store/api";
import ReportsTable from "../components/ReportsTable";
import moment from "moment";

function Dashboard() {
  const [dates, setDates] = React.useState(["", ""]);
  const [modalOpen, setModalOpen] = React.useState(false);
  const [reports, setReports] = React.useState([]);
  const dispatch = useDispatch();

  function checkDatesSet(): boolean {
    return !dates || (dates[0] == "" && dates[1] == "");
  }

  function changeDatesPicked(vals, list): void {
    setDates(vals);
  }

  async function generateInvoices({ name }) {
    const momentDates = [
      moment.utc(dates[0].toString()).startOf("day"),
      moment.utc(dates[1].toString()).endOf("day"),
    ];
    dispatch(generateReport({ dates: momentDates, name }));
  }

  async function openExistingReportsModal() {
    const { data } = await getAllReports();
    setReports(data.reports);
    setModalOpen(true);
  }

  return (
    <div className="mt-10">
      <Modal
        title="Select Existing Report"
        visible={modalOpen}
        onCancel={() => setModalOpen(false)}
        footer={null}
        width={750}
      >
        <ReportsTable
          updateReports={async () => {
            const { data } = await getAllReports();
            setReports(data.reports);
          }}
          data={reports}
        />
      </Modal>

      <h1 className="text-4xl font-medium text-center">
        Select Billing Period
      </h1>
      <Form
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          zIndex: 1,
          width: "40%",
        }}
        className="mx-auto"
        onFinish={generateInvoices}
      >
        <Form.Item
          name="name"
          rules={[{ required: true, message: "Please input a report name!" }]}
        >
          <Input size="large" placeholder="Enter Report Name" />
        </Form.Item>
        <Form.Item>
          <DatePicker.RangePicker onChange={changeDatesPicked} size="large" />
          <Button
            disabled={checkDatesSet()}
            type="primary"
            size="large"
            htmlType="submit"
          >
            Generate Invoices
          </Button>
        </Form.Item>
      </Form>
      <h1 className="text-center my-4">Or</h1>

      <div className="flex justify-center mt-4">
        <Button onClick={openExistingReportsModal} type="primary" size="large">
          View existing reports
        </Button>
      </div>
    </div>
  );
}

export default Dashboard;
