import { Button, Col, Drawer, Row, Space, Table } from "antd";
import React from "react";
import { AiOutlineDollar, AiOutlineFieldTime } from "react-icons/ai";
import { useDispatch } from "react-redux";
import { removeNestedSidebar } from "../store/modules/drawer";
import DetailsTable from "./DetailsTable";
import MetricCard from "./MetricCard";

function ProjectDetailsTable({ data }) {
  const columns = [
    {
      title: "BILLABLE RATE",
      dataIndex: "billingRate",
      key: "billingRate",
      render: (text, record) => {
        return text || 0;
      },
    },
    {
      title: "TOTAL HOURS",
      dataIndex: "totalHours",
      key: "totalHours",
    },
    {
      title: "TOTAL AMOUNT",
      dataIndex: "totalAmount",
      key: "totalAmount",
    },
  ];
  return (
    <Table
      className="mt-5"
      pagination={{
        defaultPageSize: 4,
      }}
      size="large"
      columns={columns}
      dataSource={data}
      expandable={{
        expandedRowRender: (record) => (
          <DetailsTable data={record.rawEntries} />
        ),
      }}
    />
  );
}

export default function ProjectDrawerBody({ data }) {
  function formatDataForProjectTable(data) {
    if (!data) return [];
    let billingRates = new Set(
      data.projectEntries.map((entry) => entry.billable_rate)
    );
    const uniqueBillingRates = Array.from(billingRates);

    return uniqueBillingRates.map((billingRate, index) => {
      const totalHours = data.projectEntries.reduce((prev, curr) => {
        if (curr.billable_rate === billingRate) {
          return prev + curr.hours;
        }
        return prev;
      }, 0);

      const totalAmount = Number(billingRate) * totalHours;

      const billingRateEntries = data.projectEntries.filter(
        (entry) => entry.billable_rate === billingRate
      );

      return {
        key: index,
        billingRate,
        totalHours: Number(totalHours).toFixed(2),
        totalAmount: totalAmount.toFixed(2),
        rawEntries: billingRateEntries,
      };
    });
  }

  console.log(formatDataForProjectTable(data));
  return (
    <>
      <Row gutter={48}>
        <Col span={24} xl={8}>
          <h1
            style={{
              fontSize: "24px",
              fontWeight: "bolder",
            }}
          >
            Project
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: "bold",
            }}
          >
            {data?.name}
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            Code: {data?.code}
          </h1>
          <h1
            style={{
              fontSize: "18px",
              fontWeight: 500,
            }}
          >
            Bill.com Item: {data?.billAccountName}
          </h1>
        </Col>
        <Col span={12} xl={8}>
          <div className="mb-4">
            <MetricCard
              title="Billable Hours"
              value={data?.billableHours}
              icon={<AiOutlineFieldTime />}
            />
          </div>
          <div className="">
            <MetricCard
              title="Billable Amount"
              value={"$" + data?.billableAmount}
              icon={<AiOutlineDollar />}
            />
          </div>
        </Col>
        <Col span={12} xl={8}>
          <div className="mb-4">
            <MetricCard
              title="Invoice Amount"
              value={"$" + data?.invoiceAmount}
              icon={<AiOutlineDollar />}
            />
          </div>
          <div className="mb-4">
            <MetricCard
              title="Total Hours"
              value={data?.totalHours}
              icon={<AiOutlineFieldTime />}
            />
          </div>
        </Col>
      </Row>
      <ProjectDetailsTable data={formatDataForProjectTable(data)} />
    </>
  );
}
