import { createSlice } from "@reduxjs/toolkit";

export const drawerSlice = createSlice({
  name: "drawer",
  initialState: {
    active: false,
    type: null,
    data: null,
    nestedDrawer: {
      active: false,
      type: null,
      data: null,
    },
  },
  reducers: {
    activateDrawer(state, action) {
      state.active = true;
      state.type = action.payload.type;
      state.data = action.payload.data;
    },
    activateNestedDrawer(state, action) {
      state.nestedDrawer.active = true;
      state.nestedDrawer.type = action.payload.type;
      state.nestedDrawer.data = action.payload.data;
    },
    removeSidebar(state) {
      state.active = false;
      state.type = null;
      state.data = null;
    },
    removeNestedSidebar(state) {
      state.nestedDrawer.active = false;
      state.nestedDrawer.type = null;
      state.nestedDrawer.data = null;
    },
  },
});

export const DRAWER_TYPES = {
  CLIENT: "CLIENT",
  PROJECT: "PROJECT",
  DETAIL: "DETAIL",
  CHARGE: "CHARGE",
};

export const getDrawerState = (state) => state.drawer;

export const {
  activateDrawer,
  removeSidebar,
  activateNestedDrawer,
  removeNestedSidebar,
} = drawerSlice.actions;
export default drawerSlice.reducer;
