import React from "react";
import { useSelector } from "react-redux";
import { DRAWER_TYPES, getDrawerState } from "../store/modules/drawer";
import ClientDrawer from "./ClientDrawer";

function Drawer() {
  const drawerState = useSelector(getDrawerState);

  return (
    <>
      <ClientDrawer
        visible={drawerState.active && drawerState.type === DRAWER_TYPES.CLIENT}
        clientId={drawerState.data?._id}
      />
    </>
  );
}

export default Drawer;
