import axios, { AxiosResponse } from "axios";
import { removeSidebar } from "./modules/drawer";
import { setLoading } from "./modules/loading";
import { incrementStep } from "./modules/navbar";
import { reportGenerated, updateReduxClientApproval } from "./modules/report";
import { notification } from "antd";
// const _axios = axios.create({
//   baseURL: "https://cfoshare-server-cfoshare-tahirmontgomery.cloud.okteto.net/",
//   withCredentials: true,
// });

const _axios = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URI,
  withCredentials: true,
});

export interface ReduxAsyncMiddlewareObj {
  call: Function;
  payload: any;
}

export function generateReport(data) {
  const obj: ReduxAsyncMiddlewareObj = {
    call: async function (dispatch, store, payload) {
      dispatch(setLoading(true));
      _axios
        .post("reports", {
          startDate: payload.dates[0],
          endDate: payload.dates[1],
          name: payload.name,
        })
        .then(({ data }) => {
          dispatch(reportGenerated(data.report));
          dispatch(incrementStep());
        })
        .finally(() => dispatch(setLoading(false)));
    },
    payload: data,
  };
  return obj;
}

export function approveClient(data) {
  const obj: ReduxAsyncMiddlewareObj = {
    call: async function (dispatch, store, payload) {
      _axios
        .post("reports/approve/client", {
          clientId: payload.clientId,
          reportId: payload.reportId,
          approveStatus: payload.approveStatus,
          includeItemization: payload.includeItemization,
        })
        .then(({ data }) => {
          dispatch(
            updateReduxClientApproval({
              id: data.client._id,
              approveStatus: payload.approveStatus,
              includeItemization: payload.includeItemization,
            })
          );
          dispatch(removeSidebar());
        })
        .catch((e) =>
          notification.error({
            message: "Error!",
            description: e.response.data.error.message,
            placement: "bottomLeft",
            duration: 4,
          })
        );
    },
    payload: data,
  };
  return obj;
}

export function login() {
  window.location.href = `https://id.getharvest.com/oauth2/authorize?client_id=${process.env.REACT_APP_HARVEST_OAUTH_ID}&response_type=code`;
}

export function checkLoginStatus() {
  return _axios.get("/oauth/status", { withCredentials: true });
}

export function logout() {
  _axios
    .post("/oauth/logout", { withCredentials: true })
    .then(() => (window.location.href = `${window.location.origin}/login`));
}

export function refreshReport(_id) {
  return _axios.post("/reports/refresh", { reportId: _id });
}

export function getAllReports() {
  return _axios.get("/reports");
}

export function getReportById(id) {
  return _axios.get("/reports", {
    params: {
      fullReport: true,
      customQuery: {
        _id: id,
      },
    },
  });
}

export function deleteReport(ids): Promise<AxiosResponse> {
  return _axios.delete("/reports/", {
    data: {
      ids,
    },
  });
}

export function deferProjects(projectIds, deferValue, clientId, reportId) {
  return _axios.post("/reports/defer", {
    projectIds,
    deferValue,
    reportId,
    clientId,
  });
}

export function createCustomEntry(entry, clientId, reportId) {
  return _axios.post("reports/entry", {
    entry,
    clientId,
    reportId,
  });
}

export function deleteCustomEntry(entry, reportId) {
  return _axios.delete("reports/entry", {
    data: {
      entry,
      reportId,
    },
  });
}

export function getReportSummary(reportId) {
  return _axios.get("reports/summary", {
    params: {
      reportId,
      onlyApproved: true,
    },
  });
}

export function updateProject(payload) {
  return _axios.put("reports/project", {
    payload,
  });
}

export function getBillItems() {
  return _axios.get("bill/items");
}

export function submitInvoices(reportId) {
  return _axios.post("/reports/submit", {
    reportId,
  });
}

export function getReportFiles(reportId) {
  return _axios.get("/reports/file", {
    params: {
      reportId,
    },
    responseType: "blob",
  });
}
