import { Spin } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { getLoadingStatus } from "../store/modules/loading";

function LoadingWrapper({ children }) {
  const isLoading = useSelector(getLoadingStatus);
  return (
    <Spin size="large" spinning={isLoading}>
      {children}
    </Spin>
  );
}

export default LoadingWrapper;
