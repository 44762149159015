import React from "react";
import { Steps } from "antd";
import cfoLogo from "../assets/pictures/cfoshare-logo 1.png";
import { useDispatch, useSelector } from "react-redux";
import {
  getCurrentStep,
  getCurrentUser,
  setStep,
} from "../store/modules/navbar";
import { Menu, Dropdown } from "antd";
import { AiFillCaretDown } from "react-icons/ai";
import { logout, refreshReport } from "../store/api";
import { getCurrentReport, reportGenerated } from "../store/modules/report";
import { setLoading } from "../store/modules/loading";

const menu = (report, dispatch) => (
  <Menu>
    <Menu.Item>
      <a onClick={async () => await logout()}>Logout</a>
    </Menu.Item>
    <Menu.Item>
      <a
        onClick={async () => {
          if (!report) {
            return;
          }
          dispatch(setLoading(true));
          const { data } = await refreshReport(report._id);
          dispatch(reportGenerated(data.report));
          dispatch(setLoading(false));
        }}
      >
        Refresh Report
      </a>
    </Menu.Item>
  </Menu>
);

const { Step } = Steps;

function Navbar() {
  const currentStep = useSelector(getCurrentStep);
  const currentUser = useSelector(getCurrentUser);
  const report = useSelector(getCurrentReport);
  const dispatch = useDispatch();

  const handleChange = (step) => {
    if (
      currentStep === 1 &&
      step === 2 &&
      report.clients.filter((client) => client.approved).length > 0
    ) {
      dispatch(setStep(step));
      return;
    }
    if (currentStep < step) return;
    dispatch(setStep(step));
  };
  return (
    <div className="px-0 py-4 md:px-10 md:py-5 grid border-b-2 lg:grid-cols-navbar max-w-full items-center">
      <img className="mb-4 lg:mb-0" src={cfoLogo} alt="Logo" />

      <div style={{ zIndex: 100 }} className="hidden lg:block">
        <Steps
          className="custome-step"
          responsive={true}
          current={currentStep}
          onChange={handleChange}
        >
          <Step disabled={false} title="Select Billing Period" />
          <Step disabled={false} title="Review Reports" />
          <Step disabled={false} title="Submit Invoices" />
        </Steps>
      </div>

      <div className="ml-1 lg:ml-auto lg:mt-1">
        <Dropdown overlay={() => menu(report, dispatch)}>
          <h1 className="flex items-center">
            {currentUser} <AiFillCaretDown />
          </h1>
        </Dropdown>
      </div>

      <div className="block lg:hidden mt-2">
        <Steps className="custome-step" responsive={true} current={currentStep}>
          <Step disabled={false} title="Select Billing Period" />
          <Step disabled={false} title="Review Reports" />
          <Step disabled={false} title="Submit Invoices" />
        </Steps>
      </div>
    </div>
  );
}

export default Navbar;
