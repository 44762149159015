import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Navbar from "./components/Navbar";
import Dashboard from "./pages/Dashboard";
import "./App.less";
import { getCurrentStep } from "./store/modules/navbar";
import { useSelector } from "react-redux";
import LoadingWrapper from "./components/LoadingWrapper";
import ViewReport from "./pages/ViewReport";
import Drawer from "./components/Drawer";
import Login from "./pages/Login";
import AuthWrapper from "./components/AuthWrapper";
import SubmitReport from "./pages/SubmitReport";

function App() {
  const currentStep = useSelector(getCurrentStep);

  function getCurrentComponentByStep() {
    switch (currentStep) {
      case 0:
        return <Dashboard />;
      case 1:
        return <ViewReport />;
      case 2:
        return <SubmitReport />;
      default:
        return;
    }
  }
  return (
    <div className="min-h-screen relative">
      <Router>
        <Switch>
          <Route path="/login">
            <Login />
          </Route>
          <Route path="/">
            <AuthWrapper>
              <Navbar />
              <LoadingWrapper>{getCurrentComponentByStep()}</LoadingWrapper>
              <div className="footer">
                <img
                  hidden={currentStep !== 0}
                  className="absolute left-0 bottom-0 w-full object-fill"
                  src={`${process.env.PUBLIC_URL}/wave.svg`}
                  alt="Footer"
                  style={{ zIndex: -1 }}
                />
              </div>
            </AuthWrapper>
          </Route>
        </Switch>
      </Router>
      <Drawer />
    </div>
  );
}

export default App;
