import { Button, message, Space, Table } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { getReportById, deleteReport } from "../store/api";
import { incrementStep } from "../store/modules/navbar";
import { reportGenerated } from "../store/modules/report";

function ReportsTable({ data, updateReports }) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [selecting, setSelecting] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [currentReport, setCurrentReport] = useState({ _id: null });
  const [selectedProjectKeys, setSelectedProjectKeys] = useState([]);

  async function selectReport(report) {
    setCurrentReport(report);
    setSelecting(true);
    setIsLoading(true);
    try {
      const { data } = await getReportById(report._id);
      dispatch(reportGenerated(data.reports[0]));
      dispatch(incrementStep());
    } catch (err) {
      console.log(err);
      setIsLoading(false);
      setSelecting(false);
    }
  }

  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      setSelectedProjectKeys(selectedRowKeys);
      console.log(selectedRowKeys);
    },
  };

  async function deleteReports() {
    if (selectedProjectKeys.length === 0) return;
    setTableLoading(true);
    try {
      await deleteReport(selectedProjectKeys);
      await updateReports();
      message.success("Successfully deleted reports!");
    } catch (err) {
      console.log(err);
      message.error("Error occurred while deleting reports");
    } finally {
      setTableLoading(false);
    }
  }

  async function deleteSingleReport(report) {
    setCurrentReport(report);
    setDeleting(true);
    setIsLoading(true);
    try {
      await deleteReport([report._id]);
      await updateReports();
      message.success("Successfully deleted report!");
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
      setDeleting(false);
    }
  }

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
    },
    {
      title: "Start Date",
      dataIndex: "startDate",
      render: (text, record) => {
        return moment.utc(text).format("MMM Do YY");
      },
    },
    {
      title: "End Date",
      dataIndex: "endDate",
      render: (text, record) => {
        return moment.utc(text).format("MMM Do YY");
      },
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => {
        return (
          <Space size="middle">
            <Button
              onClick={() => selectReport(record)}
              type="primary"
              style={{ background: "green" }}
              loading={
                isLoading && currentReport?._id === record._id && selecting
              }
            >
              Select
            </Button>
            <Button
              onClick={() => deleteSingleReport(record)}
              type="primary"
              style={{ background: "red" }}
              loading={
                isLoading && currentReport?._id === record._id && deleting
              }
            >
              Delete
            </Button>
          </Space>
        );
      },
    },
  ];

  const selectionsConfig = [
    {
      key: "delete_reports",
      text: "Delete Report/s",
      onSelect: async (keys) => await deleteReports(),
    },
  ];
  return (
    <Table
      pagination={{
        defaultPageSize: 10,
      }}
      size="large"
      columns={columns}
      dataSource={data}
      loading={tableLoading}
      id="project-table"
      rowKey={"_id"}
      rowSelection={{
        type: "checkbox",
        ...rowSelection,
        selections: selectionsConfig,
      }}
    />
  );
}

export default ReportsTable;
