import { Button, Space, Table } from "antd";
import moment from "moment";
import React from "react";
import { useDispatch } from "react-redux";
import { DRAWER_TYPES, activateNestedDrawer } from "../store/modules/drawer";

function DetailsTable({ data }) {
  const dispatch = useDispatch();
  const openDetailDrawer = (detail) => {
    dispatch(
      activateNestedDrawer({
        type: DRAWER_TYPES.DETAIL,
        data: detail,
      })
    );
  };
  const columns = [
    {
      title: "EMPLOYEE",
      dataIndex: ["user", "name"],
      key: "user.name",
    },
    {
      title: "PROJECT",
      dataIndex: ["project", "name"],
    },
    {
      title: "DATE",
      dataIndex: "spent_date",
      render: (text, record) => {
        return moment.utc(text).calendar();
      },
    },
    {
      title: "HOURS",
      dataIndex: "hours",
    },
    {
      title: "BILLABLE RATE",
      dataIndex: "billable_rate",
      render: (text, record) => {
        if (Number(text) > 0) {
          return text;
        }
        return 0;
      },
    },
    {
      title: "BILLABLE",
      render: (text, record) => {
        if (text) {
          return "Yes";
        }
        return "No";
      },
      key: "billable",
    },
    {
      title: "ACTION",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() => openDetailDrawer(record)}
            type="primary"
            style={{ background: "green" }}
          >
            View
          </Button>
        </Space>
      ),
    },
    // {
    //   title: "ACTION",
    //   key: "action",
    //   render: (text, record) => (
    //     <Space size="middle">
    //       <Button
    //         onClick={() => openProjectDrawer(record)}
    //         type="primary"
    //         style={{ background: "green" }}
    //       >
    //         View
    //       </Button>
    //     </Space>
    //   ),
    // },
  ];

  return (
    <Table
      pagination={{
        defaultPageSize: 6,
      }}
      size="large"
      columns={columns}
      dataSource={data}
      id="project-table"
    />
  );
}

export default DetailsTable;
