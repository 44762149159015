import React, { useState } from "react";
import {
  Drawer,
  Button,
  Space,
  Row,
  Col,
  Statistic,
  Modal,
  Dropdown,
  Menu,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  DRAWER_TYPES,
  getDrawerState,
  removeSidebar,
} from "../store/modules/drawer";
import {
  AiOutlineBell,
  AiOutlineDollar,
  AiOutlineFieldTime,
} from "react-icons/ai";
import MetricCard from "./MetricCard";
import { Divider } from "antd";
import ProjectTable from "./ProjectTable";
import DetailsTable from "./DetailsTable";
import ChargesTable from "./ChargesTable";
import ProjectDrawerBody from "./ProjectNestedDrawer";
import DetailNestedDrawer from "./DetailNestedDrawer";
import NestedDrawerWrapper from "./NestedDrawerWrapper";
import { getByTitle } from "@testing-library/react";
import { approveClient } from "../store/api";
import { getClientById, getCurrentReport } from "../store/modules/report";
import CustomEntryModal from "./CustomEntryModal";

function ClientCard({ name }) {
  return (
    <div className="flex justify-start items-center h-24 w-48 shadow-lg rounded-xl px-2">
      <h2 className="font-bold text-xl">{name}</h2>
    </div>
  );
}

function ClientDrawerBody({ data }) {
  return (
    <div>
      <h1
        style={{
          fontSize: "24px",
          fontWeight: "bolder",
        }}
      >
        Client
      </h1>

      <Row gutter={48}>
        <Col span={24} xl={6}>
          <ClientCard name={data?.name} />
        </Col>
        <Col className="mt-3 lg:mt-0" span={12} xl={6}>
          <div className="mb-4">
            <MetricCard
              title="Billable Hours"
              value={data?.billableHours}
              icon={<AiOutlineFieldTime />}
            />
          </div>
          <div className="">
            <MetricCard
              title="Billable Amount"
              value={"$" + data?.billableAmount}
              icon={<AiOutlineDollar />}
            />
          </div>
        </Col>
        <Col className="mt-3 lg:mt-0" span={12} xl={6}>
          <div className="mb-4">
            <MetricCard
              title="Total Hours"
              value={data?.totalHours}
              icon={<AiOutlineFieldTime />}
            />
          </div>
          <div className="">
            <MetricCard
              title="Deposit"
              value={"$" + data?.depositAmount}
              icon={<AiOutlineDollar />}
            />
          </div>
        </Col>
        <Col className="mt-3 lg:mt-0" span={12} xl={6}>
          <div className="mb-4">
            <MetricCard
              title="Invoice Total"
              value={`$${data?.invoiceTotal}`}
              icon={<AiOutlineBell />}
            />
          </div>
          <div className="">
            <MetricCard
              title="Deposit Applied"
              value={"$" + data?.depositApplied}
              icon={<AiOutlineBell />}
            />
          </div>
        </Col>
      </Row>
      <Divider />
      <h1
        style={{
          fontSize: "24px",
          fontWeight: "bolder",
        }}
      >
        Summary
      </h1>
      <Row>
        <Col span={24}>
          <ProjectTable clientId={data?._id} data={data?.projects} />
        </Col>
      </Row>

      <Divider />

      <h1
        style={{
          fontSize: "24px",
          fontWeight: "bolder",
        }}
      >
        Charges/Discounts/Recurring
      </h1>
      <Row>
        <Col span={24}>
          <ChargesTable data={data?.customEntries} />
        </Col>
      </Row>

      <Divider />

      <h1
        style={{
          fontSize: "24px",
          fontWeight: "bolder",
        }}
      >
        Details
      </h1>
      <Row>
        <Col span={24}>
          <DetailsTable data={data?.rawEntries} />
        </Col>
      </Row>
    </div>
  );
}

function ClientDrawer({ clientId, visible }) {
  const [entryModalOpen, setEntryModalOpen] = useState(false);
  const dispatch = useDispatch();
  const drawerState = useSelector(getDrawerState);
  const report = useSelector(getCurrentReport);

  const getClientById = (clientId) =>
    report.clients.filter((client) => clientId === client._id)[0];

  const openEntryModal = () => {
    setEntryModalOpen(true);
  };

  const approved = getClientById(clientId)?.approved;
  const onMenuClick = async (e) => {
    dispatch(
      approveClient({
        clientId,
        reportId: report._id,
        approveStatus: true,
        includeItemization: true,
      })
    );
  };
  const menu = (
    <Menu onClick={onMenuClick}>
      <Menu.Item key="audit">Approve with Audit</Menu.Item>
    </Menu>
  );

  return (
    <>
      <CustomEntryModal
        onCancel={setEntryModalOpen}
        open={entryModalOpen}
        client={getClientById(clientId)}
      />

      <Drawer
        onClose={() => dispatch(removeSidebar())}
        title="Review Client Report"
        width="100%"
        destroyOnClose
        visible={visible}
        placement="right"
        extra={
          <Space>
            <Dropdown.Button
              onClick={() =>
                approved
                  ? dispatch(
                      approveClient({
                        clientId,
                        reportId: report._id,
                        approveStatus: false,
                        includeItemization: false,
                      })
                    )
                  : dispatch(
                      approveClient({
                        clientId,
                        reportId: report._id,
                        approveStatus: true,
                        includeItemization: false,
                      })
                    )
              }
              type={!approved ? "primary" : "default"}
              overlay={!approved ? menu : <></>}
            >
              {approved ? "Unapprove" : "Approve"}
            </Dropdown.Button>

            <Button
              type="primary"
              style={{
                background: "blue",
              }}
              onClick={() => openEntryModal()}
            >
              Add Entry
            </Button>
            <Button onClick={() => dispatch(removeSidebar())}>Cancel</Button>
          </Space>
        }
      >
        <ClientDrawerBody data={getClientById(clientId)} />
        <NestedDrawerWrapper
          title={getTitle(drawerState.nestedDrawer.type)}
          visible={drawerState.nestedDrawer.active}
        >
          {getActiveDrawer(drawerState)}
        </NestedDrawerWrapper>
      </Drawer>
    </>
  );
}

export default ClientDrawer;

function getTitle(type) {
  switch (type) {
    case DRAWER_TYPES.PROJECT:
      return "Project Details";
    case DRAWER_TYPES.DETAIL:
      return "Employee Entry Details";
    case DRAWER_TYPES.CHARGE:
      return "Custom Entry Details";
    default:
      return "";
  }
}

function getActiveDrawer(
  drawerState
):
  | string
  | number
  | boolean
  | {}
  | React.ReactElement<any, string | React.JSXElementConstructor<any>>
  | React.ReactNodeArray
  | React.ReactPortal
  | null
  | undefined {
  if (!drawerState.nestedDrawer.active) return null;

  switch (drawerState.nestedDrawer.type) {
    case DRAWER_TYPES.DETAIL:
      return <DetailNestedDrawer data={drawerState.nestedDrawer.data} />;
    case DRAWER_TYPES.PROJECT:
      return <ProjectDrawerBody data={drawerState.nestedDrawer.data} />;
    default:
      return null;
  }
}
